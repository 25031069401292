<template>
  <div style="height: 100%">
    <!-- 搜素框 -->
    <div style="float: right">
      <el-button
        v-show="
          !isDetailEditMode &&
          authorization('/home-admin/order/order-manage/examine') &&
          tableData.status === 3
        "
        type="success"
        @click="examines()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        >审核</el-button
      >
      <!--      scrollToDiv()    )-->
      <el-button
        type="warning"
        @click="openCompleteDialog()"
        :loading="imgDownloadLoading"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="authorization('/home-admin/order/order-manage/completeOrder')"
        >完成安装</el-button
      >

      <!-- 对话框 -->
      <el-dialog
        title="填写信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <!-- 表单 -->
        <el-form
          ref="form"
          :model="completeInstallForm"
          :rules="rulesX"
          label-width="100px"
        >
          <!-- 使用 el-date-picker 替代 el-input 实现日期时间选择 -->
          <el-form-item label="完工时间" prop="completeTimeX">
            <el-date-picker
              v-model="completeInstallForm.completeTimeX"
              type="datetime"
              placeholder="选择完工时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="详细地址" prop="detailAddressX">
            <el-input v-model="completeInstallForm.detailAddressX"></el-input>
          </el-form-item>
        </el-form>

        <!-- 对话框的操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </span>
      </el-dialog>

      <el-button
        type="success"
        @click="download()"
        :loading="imgDownloadLoading"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="
          !isDetailEditMode && authorization('/home-admin/order/order-manage/download')
        "
        >下载图片</el-button
      >

      <el-button
        type="primary"
        @click="editDetail()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="
          !isDetailEditMode && authorization('/home-admin/order/order-manage/download')
        "
        >修改</el-button
      >
      <el-button
        type="success"
        @click="saveEditdDetail()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="
          isDetailEditMode && authorization('/home-admin/order/order-manage/download')
        "
        >保存</el-button
      >
      <el-button
        type="danger"
        @click="cancelEditDetail()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="
          isDetailEditMode && authorization('/home-admin/order/order-manage/download')
        "
        >取消</el-button
      >

      <el-button
        type="primary"
        @click="downloadVideo()"
        :loading="imgDownloadLoading"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="
          !isDetailEditMode && authorization('/home-admin/order/order-manage/download')
        "
        >下载视频</el-button
      >
      <el-button
        type="info"
        @click="goback()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="!isDetailEditMode"
        >返回</el-button
      >
    </div>
    <el-descriptions
      title="订单详情"
      :column="4"
      border
      size="mini"
      :labelStyle="{ width: '8%' }"
      :contentStyle="{ width: '17%' }"
      style="
        background-color: #fff;
        padding: 20px;
        padding-bottom: 0;
        border-radius: 8px;
        border-top-left-radius: 0%;
        border-top-right-radius: 0%;
      "
    >
      <el-descriptions-item label="投诉单号" v-if="$route.query.isRepairOrder">{{
        tableData.reserve5
      }}</el-descriptions-item>
      <el-descriptions-item label="问题来源" v-if="$route.query.isRepairOrder">{{
        tableData.sourceOrderIdStr
      }}</el-descriptions-item>
      <el-descriptions-item label="是否有责" v-if="$route.query.isRepairOrder">
        <el-select
          v-if="isDetailEditMode"
          v-model="editTime.isResponsible"
          placeholder="请选择是否有责"
          style="width: 100%"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <span v-else>{{ tableData.isResponsibleStr }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="订单编号">{{
        tableData.orderNum
      }}</el-descriptions-item>
      <el-descriptions-item label="所属公司">{{
        tableData.companyName
      }}</el-descriptions-item>
      <el-descriptions-item label="所属区域">{{
        tableData.regionName
      }}</el-descriptions-item>
      <el-descriptions-item label="充电桩类型">{{
        tableData.storeName
      }}</el-descriptions-item>
      <el-descriptions-item label="住宅类型">{{
        houseTypeFormat(tableData)
      }}</el-descriptions-item>
      <el-descriptions-item label="是否自布线">{{
        tableData.isWiring ? "是" : "否"
      }}</el-descriptions-item>

      <el-descriptions-item label="订单类型">{{
        orderTypeFormat(tableData)
      }}</el-descriptions-item>
      <el-descriptions-item label="维修类型" v-if="tableData.orderType === 2">{{
        repairTypeFormat(tableData)
      }}</el-descriptions-item>
      <el-descriptions-item label="原订单编号" v-if="tableData.orderType === 2">{{
        tableData.oldOrderNum
      }}</el-descriptions-item>
      <el-descriptions-item label="故障描述" v-if="tableData.orderType === 2">{{
        tableData.faultDescription
      }}</el-descriptions-item>
      <el-descriptions-item label="客户名">{{
        tableData.customerName
      }}</el-descriptions-item>
      <el-descriptions-item label="客户联系电话">{{
        tableData.phone
      }}</el-descriptions-item>
      <el-descriptions-item label="主机厂订单号">{{
        tableData.pileModel
      }}</el-descriptions-item>
      <el-descriptions-item label="上游订单号">{{
        tableData.reserve2
      }}</el-descriptions-item>
      <el-descriptions-item label="车架号">{{ tableData.frameNo }}</el-descriptions-item>
      <el-descriptions-item label="品牌">{{
        tableData.deviceBrandName
      }}</el-descriptions-item>
      <el-descriptions-item label="车型">{{ tableData.modelName }}</el-descriptions-item>
      <el-descriptions-item label="订单金额">{{ tableData.price }}</el-descriptions-item>
      <el-descriptions-item label="负责师傅">{{
        tableData.masterWorkerName
      }}</el-descriptions-item>

      <el-descriptions-item label="完工时间" class="center-content">
        <el-date-picker
          v-if="isDetailEditMode"
          v-model="editTime.installTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          class="custom-date-picker"
          size="mini"
        >
        </el-date-picker>

        <span v-if="!isDetailEditMode" class="install-time-text">
          {{ dateFormat(tableData.installTime) }}
        </span>
      </el-descriptions-item>

      <el-descriptions-item label="完工提交时间">{{
        dateFormat(tableData.installFinishTime)
      }}</el-descriptions-item>
      <el-descriptions-item label="录单人">{{
        tableData.recordUserName
      }}</el-descriptions-item>
      <el-descriptions-item label="录单时间">{{
        dateFormat(tableData.recordTime)
      }}</el-descriptions-item>

      <el-descriptions-item label="预约时间">
        <el-date-picker
          v-if="isDetailEditMode"
          v-model="editTime.subscribeTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          class="custom-date-picker"
          size="mini"
        >
        </el-date-picker>
        <span v-if="!isDetailEditMode" class="install-time-text">
          {{ dateFormat(tableData.subscribeTime) }}
        </span>
      </el-descriptions-item>

      <el-descriptions-item label="是否带桩">
        <el-select
          v-if="isDetailEditMode"
          v-model="editTime.isDockingRequired"
          placeholder="请选择是否带桩"
          style="width: 100%"
        >
          <el-option :label="'是'" :value="true"></el-option>
          <el-option :label="'否'" :value="false"></el-option>
        </el-select>
        <span v-else>{{ tableData.isDockingRequired ? "是" : "否" }}</span>
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      :column="4"
      border
      size="mini"
      :labelStyle="{ width: '8%' }"
      style="
        background-color: #fff;
        padding: 20px;
        padding-top: 5px;
        border-radius: 8px;
        border-top-left-radius: 0%;
        border-top-right-radius: 0%;
      "
    >
      <el-descriptions-item span="7" label="详细地址">
        <el-input
          v-if="isDetailEditMode"
          v-model="editTime.detailAddress"
          placeholder="请输入详细地址"
        />
        <span v-else>{{ tableData.detailAddress }}</span>
      </el-descriptions-item>

      <el-descriptions-item span="7" label="订单备注">{{
        tableData.orderRemark
      }}</el-descriptions-item>
      <el-descriptions-item span="7" label="跟进备注" v-if="false">
        <div
          v-for="(item, index) in getFollowRemarkList(tableData.followRemark)"
          style="width: 100%; text-align: left; margin: 0"
          :key="index"
        >
          {{ item }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item span="7" label="驳回理由">{{
        tableData.rejectRemark
      }}</el-descriptions-item>
    </el-descriptions>
    <!-- 跟进详情 -->
    <div style="float: right">
      <el-button
        type="success"
        @click="addFollow()"
        size="mini"
        style="margin-top: 20px; margin-right: 20px"
        v-show="authorization('/home-admin/order/order-manage/addFollow')"
        >新增</el-button
      >
    </div>
    <el-descriptions
      title="跟进详情"
      :column="3"
      border
      :labelStyle="{ width: '8%' }"
      :contentStyle="{ width: '17%' }"
      style="margin-top: 15px; background-color: #fff; padding: 20px; border-radius: 8px"
      size="mini"
    >
      <template v-for="(item, index) in modelTableData">
        <el-descriptions-item :key="`followTime-${index}`" label="跟进时间">
          {{ dateFormat(item.followTime) }}
        </el-descriptions-item>
        <el-descriptions-item :key="`followerName-${index}`" label="跟进人">
          {{ item.followerName }}
        </el-descriptions-item>
        <el-descriptions-item :key="`followInfo-${index}`" label="跟进备注">
          {{ item.followInfo }}
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <!-- 设备详情 -->
    <el-descriptions
      title="设备详情"
      :column="4"
      border
      :labelStyle="{ width: '8%' }"
      :contentStyle="{ width: '17%' }"
      style="margin-top: 15px; background-color: #fff; padding: 20px; border-radius: 8px"
      size="mini"
    >
      <template slot="extra">
        <el-button
          type="primary"
          size="small"
          @click="deviceUpdate"
          v-show="
            !deviceUpdateClick &&
            authorization('/home-admin/order/order-manage/edit') &&
            (tableData.orderType !== 2 ||
              (tableData.orderType == 2 && tableData.repairType === 1))
          "
          >修改</el-button
        >
        <el-button
          type="success"
          size="small"
          @click="saveUpdate"
          v-show="
            deviceUpdateClick && authorization('/home-admin/order/order-manage/edit')
          "
          >保存</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="deviceUpdateCancle"
          v-show="
            deviceUpdateClick && authorization('/home-admin/order/order-manage/edit')
          "
          >取消</el-button
        >
      </template>

      <el-descriptions-item label="设备编号">
        <el-select
          v-if="deviceUpdateClick"
          v-model="tableData.deviceNum"
          placeholder="请选择设备编号或手动输入"
          size="small"
          style="width: 100%"
          filterable
          default-first-option
        >
          <!-- 遍历 deviceNums 数组生成每个设备编号的选项 -->
          <el-option
            v-for="item in deviceListX"
            :key="item.deviceNum"
            :label="item.deviceNum"
            :value="item.deviceNum"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.deviceNum }}</span>
      </el-descriptions-item>

      <el-descriptions-item label="充电桩类型">
        <el-select
          v-if="deviceUpdateClick"
          filterable
          v-model="tableData.storeId"
          placeholder="请选择充电桩类型"
          @change="$forceUpdate()"
          style="width: 100%"
        >
          <el-option
            v-for="item in deviceCategoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.storeName }}</span>
      </el-descriptions-item>
      <!-- <el-descriptions-item label="是否自提桩">{{ tableData.isSelfPickup
        == '1' ? "是" : "否" }}</el-descriptions-item> -->
      <el-descriptions-item label="电源点来源">
        <el-select
          v-if="deviceUpdateClick"
          :disabled="tableData.orderType == 3"
          filterable
          v-model="tableData.powerSource"
          placeholder="请选择充电源来源"
          @change="$forceUpdate()"
          style="width: 100%"
        >
          <el-option
            v-for="item in powerSourceCol"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.powerSource }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="电缆品牌">
        <div v-if="deviceUpdateClick">
          <el-select
            filterable
            v-model="tableData.cableBrandId"
            :disabled="tableData.orderType == 3"
            placeholder="请选择电缆品牌"
            @change="$forceUpdate()"
            :style="tableData.cableBrandId === -1 ? 'width: 50%' : 'width: 100%'"
          >
            <el-option
              v-for="item in cableBrandCol"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-input
            size="small"
            style="width: 50%; height: 100%"
            v-show="deviceUpdateClick && tableData.cableBrandId === -1"
            v-model="tableData.cableBrandName"
          ></el-input>
        </div>
        <span v-else>{{ tableData.cableBrandName }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="用线长度">
        <el-input-number
          size="small"
          v-if="deviceUpdateClick"
          style="width: 100%"
          v-model="tableData.lineLength"
          :disabled="tableData.orderType == 3"
        ></el-input-number>
        <span v-else>{{ tableData.lineLength }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="增项费用">
        <el-input-number
          size="small"
          v-if="deviceUpdateClick"
          style="width: 100%"
          v-model="tableData.addOutlay"
          :disabled="tableData.orderType == 3"
        ></el-input-number>
        <span v-else>{{ tableData.addOutlay }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="是否购买延长线">
        <el-select
          v-if="deviceUpdateClick"
          :disabled="tableData.orderType == 3"
          filterable
          v-model="tableData.purchaseExtension"
          placeholder="请选择是否购买延长线"
          @change="$forceUpdate()"
          style="width: 100%"
        >
          <el-option
            v-for="item in choiceList2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.purchaseExtension === 1 ? "已购买" : "未购买" }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="是否签免责">
        <el-select
          v-if="deviceUpdateClick"
          :disabled="tableData.orderType == 3"
          filterable
          v-model="tableData.signExemption"
          placeholder="请选择是否签免责"
          @change="$forceUpdate()"
          style="width: 100%"
        >
          <el-option
            v-for="item in choiceList1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.signExemption === 1 ? "已签" : "未签" }}</span>
      </el-descriptions-item>

      <el-descriptions-item label="是否自布线">
        <el-select
          v-if="deviceUpdateClick"
          :disabled="tableData.orderType == 3"
          filterable
          v-model="tableData.isWiring"
          placeholder="请选择是否自布线"
          @change="$forceUpdate()"
          style="width: 100%"
        >
          <el-option
            v-for="item in choiceListX"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span v-else>{{ tableData.isWiring ? "是" : "否" }}</span>
      </el-descriptions-item>

      <el-descriptions-item label="选择住宅类型">
        <el-select
          v-if="deviceUpdateClick"
          v-model="tableData.houseType"
          placeholder="请选择住宅类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in houseTypeList"
            :key="item.id"
            :label="item.houseTypeName"
            :value="item.id"
          ></el-option>
        </el-select>
        <span v-else>{{ getHouseTypeName(tableData.houseType) }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 图片 -->
    <el-card
      style="margin-top: 15px; border-radius: 8px"
      :body-style="{ padding: '0px' }"
    >
      <div slot="header" class="clearfix" ref="targetDiv">
        <span><strong>图片信息</strong></span>
        <div style="float: right">
          <el-button
            type="primary"
            size="small"
            @click="handleImageUpdate"
            v-show="authorization('/home-admin/order/order-manage/editImage')"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="handleImageReset"
            v-show="authorization('/home-admin/order/order-manage/resetImage')"
            >重置图片模板</el-button
          >
        </div>
      </div>
      <el-descriptions
        direction="vertical"
        :column="8"
        border
        size="mini"
        style="background-color: #fff; padding: 10px"
      >
        <template v-for="img in templateDetailList">
          <el-descriptions-item
            :label="img.imageName"
            :labelStyle="
              templateDetailList.indexOf(img) === templateDetailList.length - 1
                ? ''
                : { width: '12.5%' }
            "
            :contentStyle="{ height: '200px' }"
            :key="img.id"
          >
            <div>
              <el-image
                :src="changeImageUrl(img.miniUrl)"
                style="height: 200px"
                v-show="img.miniUrl !== null && img.miniUrl != ''"
                :preview-src-list="new Array(1).fill(changeImageUrl(img.url))"
              ></el-image>
            </div>
          </el-descriptions-item>
        </template>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        border
        size="mini"
        style="
          background-color: #fff;
          padding: 0 10px 10px 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        "
      >
        <el-descriptions-item
          label="免责图"
          labelStyle="width: 12.5%;"
          contentStyle="height:200px"
        >
          <div>
            <el-image
              v-for="(item, index) in reserve3List"
              :key="index"
              :src="item"
              :preview-src-list="reserve3List"
              style="height: 200px; width: 13%; margin-right: 5px; float: left"
            ></el-image>
          </div>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        border
        size="mini"
        style="
          background-color: #fff;
          padding: 0 10px 10px 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        "
      >
        <el-descriptions-item
          label="勘测确认图"
          labelStyle="width: 87.5%;"
          contentStyle="height:200px"
        >
          <div>
            <el-image
              v-for="(item, index) in surveyImageList"
              :key="index"
              :src="item"
              :preview-src-list="surveyImageList"
              style="height: 200px; width: 13%; margin-right: 5px; float: left"
            ></el-image>
          </div>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        border
        size="mini"
        v-show="courierImageList != null && courierImageList.length > 0"
        style="
          background-color: #fff;
          padding: 0 10px 10px 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        "
      >
        <el-descriptions-item
          label="快递单据图"
          labelStyle="width: 100%;"
          contentStyle="height:200px"
        >
          <el-image
            :src="
              courierImageList != null && courierImageList.length > 0
                ? courierImageList[0]
                : ''
            "
            :preview-src-list="courierImageList"
            v-show="courierImageList != null && courierImageList.length > 0"
            style="height: 200px; width: 13%; margin-right: 5px; float: left"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        border
        size="mini"
        style="
          background-color: #fff;
          padding: 0 10px 10px 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        "
      >
        <el-descriptions-item
          label="其他多图"
          labelStyle="width: 100%;"
          contentStyle="height:200px"
        >
          <div style="overflow: auto; height: 210px">
            <el-image
              v-for="(item, index) in otherImgList"
              :key="index"
              :src="item"
              :preview-src-list="otherImgList"
              style="height: 200px; width: 13%; margin-right: 5px; float: left"
            ></el-image>
          </div>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        border
        size="mini"
        style="
          background-color: #fff;
          padding: 0 10px 10px 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        "
      >
        <el-descriptions-item
          label="相关视频"
          labelStyle="width: 100%;"
          contentStyle="height:200px"
        >
          <video
            width="100%"
            type="video/mp4"
            v-show="tableData.videoUrl != null && tableData.videoUrl != ''"
            :src="changeImageUrl(tableData.videoUrl)"
            style="height: 200px; width: 300px"
            muted="muted"
            preload="preload"
            controls="controls"
          ></video>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 订单物料相关 -->
    <div
      style="
        height: 400px;
        overflow: auto;
        background-color: #fff;
        border-radius: 8px;
        padding: 5px 20px 20px 20px;
        margin-top: 15px;
        margin-bottom: 50px;
      "
    >
      <div style="width: 100%; height: 50px; line-height: 50px">
        <strong>订单物料相关</strong>
        <el-button
          style="float: right; margin-top: 5px"
          type="primary"
          size="small"
          @click="addMaterial"
          v-show="authorization('/home-admin/order/order-manage/addMaterial')"
          >添加</el-button
        >
      </div>
      <el-table
        size="mini"
        :summary-method="getSummaries"
        show-summary
        :data="materialOrderList"
        stripe
        :header-cell-style="{ background: 'rgba(132,170,206,0.29)', color: '#343333' }"
        height="350px"
        width="100%"
        ref="tableData"
        :border="true"
      >
        <el-table-column
          prop="modelName"
          label="物料型号"
          width="300px"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="typeName"
          label="物料类型"
          align="center"
        ></el-table-column>
        <el-table-column prop="meterialPrice" label="单价" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-if="isEdit[scope.$index]"
              size="mini"
              clearable
              style="width: 100%"
              v-model.trim="scope.row.meterialPrice"
              @change="calcTotal(scope.row)"
              :min="0"
            />
            <span v-if="!isEdit[scope.$index]">{{ scope.row.meterialPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="耗材量" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-if="isEdit[scope.$index]"
              size="mini"
              clearable
              style="width: 100%"
              v-model.trim="scope.row.num"
              @change="calcTotal(scope.row)"
              :min="0"
            />
            <span v-if="!isEdit[scope.$index]">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="allPrice" label="总价" align="center"></el-table-column>
        <el-table-column prop="actualAmount" label="实收金额" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-if="isEdit[scope.$index]"
              size="mini"
              clearable
              style="width: 100%"
              v-model.trim="scope.row.actualAmount"
              :min="0"
            />
            <span v-if="!isEdit[scope.$index]">{{ scope.row.actualAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="保存"
              placement="top-end"
              v-if="
                isEdit[scope.$index] &&
                authorization('/home-admin/order/order-manage/editMaterial')
              "
            >
              <el-button
                type="success"
                icon="el-icon-check"
                circle
                size="mini"
                @click="updateMaterialSumbit(scope)"
                style="margin-left: 10px"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-end"
              v-if="
                !isEdit[scope.$index] &&
                authorization('/home-admin/order/order-manage/editMaterial')
              "
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="updateMaterial(scope.$index)"
                style="margin-left: 10px"
              ></el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-popconfirm title="确认删除吗？" @confirm="deleteMaterial(scope.row)">
                <el-button
                  content="删除"
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  style="margin-left: 10px"
                  v-show="authorization('/home-admin/order/order-manage/delMaterial')"
                ></el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="remarks" label="备注"></el-table-column>-->
      </el-table>
    </div>

    <el-dialog
      title="修改图片"
      :visible.sync="dialogUpdateImage"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="imageUpdateParams"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <div v-for="img in imageUpdateParams.templateDetailList" v-bind:key="img.key">
          <el-form-item :label="img.imageName">
            <el-upload
              action="#"
              accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
              :file-list="img.imgList"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="(file, fileList) => upload(file, fileList, img.id)"
              :on-remove="(file, fileList) => commRemove(file, fileList, img.id)"
              :on-preview="handlePictureCardPreview"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </div>
        <el-form-item label="免责图" v-show="imageUpdateParams.signExemption === 1">
          <el-upload
            action="#"
            accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
            :file-list="imageUpdateParams.reserve3List"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="(file, fileList) => upload(file, fileList, -2)"
            :on-remove="(file, fileList) => commRemove(file, fileList, -2)"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="勘测确认单">
          <el-upload
            action="#"
            accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
            :file-list="imageUpdateParams.surveyImageList"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="(file, fileList) => upload(file, fileList, -3)"
            :on-remove="(file, fileList) => commRemove(file, fileList, -3)"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="其他图片">
          <el-upload
            action="#"
            accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
            :file-list="imageUpdateParams.reserve1List"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="(file, fileList) => upload(file, fileList, -1)"
            :on-remove="(file, fileList) => commRemove(file, fileList, -1)"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="相关视频">
          <el-upload
            action="#"
            accept="video/*"
            :file-list="imageUpdateParams.videoList"
            :auto-upload="false"
            :on-change="(file, fileList) => uploadVideo(file, fileList, -99)"
            :on-remove="(file, fileList) => commRemove(file, fileList, -99)"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <video
            v-if="
              imageUpdateParams.videoUrl !== null && imageUpdateParams.videoUrl !== ''
            "
            v-bind:src="changeImageUrl(imageUpdateParams.videoUrl)"
            muted="muted"
            preload="preload"
            controls="controls"
            class="avatar video-avatar"
          ></video>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 60px">
        <el-button type="success" size="mini" @click="saveImageUpdate">确认</el-button>
        <el-button @click="dialogUpdateImage = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="审核"
      :visible.sync="dialogFormExamine"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form :model="examine" label-width="160px" size="small" style="width: 600px">
        <el-form-item label="驳回理由">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="请输入驳回理由（通过则不填写）"
            v-model="examine.rejectRemark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 60px">
        <el-popconfirm
          title="确认审核通过吗？"
          @confirm="examineOrder('orderManageArray', 6)"
        >
          <el-button slot="reference" type="success" size="mini">通过</el-button>
        </el-popconfirm>
        <el-popconfirm
          title="确认驳回吗？"
          @confirm="examineOrder('orderManageArray', 4)"
          style="margin-left: 10px"
        >
          <el-button slot="reference" type="danger" size="mini">驳回</el-button>
        </el-popconfirm>
      </div>
    </el-dialog>
    <el-dialog
      title="修改"
      :visible.sync="dialogUpdate"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="updateParams"
        label-width="160px"
        size="small"
        style="width: 500px"
      >
        <el-form-item label="设备编号">
          <el-input
            placeholder="请输入设备编号"
            v-model="updateParams.deviceNum"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 60px">
        <el-button type="success" size="mini" @click="saveUpdate">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加物料"
      :visible.sync="dialogAddMaterial"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addMaterialParams"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <el-form-item label="选择物料">
          <el-cascader
            @change="materialChanged"
            v-model="addMaterialParams.modeAndTypeId"
            :options="materialList"
            :props="optionProps"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="单价">
          <el-input
            placeholder="请输入单价"
            type="number"
            v-model="addMaterialParams.meterialPrice"
            @change="calcTotal(addMaterialParams)"
          ></el-input>
        </el-form-item>
        <el-form-item label="耗材量">
          <el-input
            placeholder="请输入耗材量"
            type="number"
            v-model="addMaterialParams.num"
            @change="calcTotal(addMaterialParams)"
          ></el-input>
        </el-form-item>
        <el-form-item label="实收金额">
          <el-input
            placeholder="请输入实收金额"
            type="number"
            v-model="addMaterialParams.actualAmount"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 60px">
        <el-button type="success" size="mini" @click="saveMaterial">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogImagePreview">
      <img width="100%" :src="imagePreviewUrl" alt="" />
    </el-dialog>
    <el-dialog
      title="新增"
      :visible.sync="dialogFollowAdd"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form :model="followArr" label-width="160px" size="small" style="width: 600px">
        <el-form-item label="客户姓名" prop="customerName" v-show="false">
          <el-input v-model="followArr.customerName"></el-input>
        </el-form-item>
        <el-form-item label="客户电话" prop="phone" v-show="false">
          <el-input v-model="followArr.phone" :min="0"></el-input>
        </el-form-item>
        <el-form-item label="跟进备注" prop="remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="请输入内容"
            v-model="followArr.followRemark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFollowAdd = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addFollowSubMit('followArr')" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { authorization } from "../../../authorization/authorization";
import { listDictByType } from "@/components/admin/common/util.js";

import {
  statusFormat,
  statusFormatByStatus,
  orderTypeFormat,
  repairTypeFormat,
  houseTypeFormat,
} from "../common/format";
import ImageCompressor from "compressorjs";
export default {
  data() {
    return {
      optionProps: {
        value: "value",
        label: "text",
        children: "children",
      },
      deviceListX: [],
      orderId: "",
      materialList: [],
      materialModelList: [],
      row: {},
      imgDownloadLoading: false,
      dialogUpdate: false,
      dialogFormExamine: false,
      dialogAddMaterial: false,
      dialogUpdateImage: false,
      dialogFollowAdd: false,
      dialogImagePreview: false,
      deviceUpdateClick: false,
      activeName: this.$route.query.activeName,
      page: this.$route.query.page,
      tableData: [],
      modelTableData: [],
      materialOrderList: [],
      templateDetailList: [],
      otherImgList: [],
      reserve3List: [],
      surveyImageList: [],
      courierImageList: [],
      companyInfo: {},
      addMaterialParams: {
        modeAndTypeId: [],
        orderId: "",
        typeId: "",
        modelId: "",
        num: "",
        meterialPrice: "",
        actualAmount: 0,
      },
      followArr: {
        orderId: "",
        customerName: "",
        phone: "",
        followRemark: "",
      },
      updateParams: {
        deviceNum: "",
        lineLength: "",
        addOutlay: "",
      },
      dialogVisible: false, // 控制对话框的显示与隐藏
      completeInstallForm: {
        orderId: "",
        completeTimeX: "",
        detailAddressX: "",
      },
      rulesX: {
        completeTimeX: [{ required: true, message: "请选择完工时间", trigger: "change" }],
        detailAddressX: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
      },
      editTime: {
        subscribeTime: "",
        isDockingRequired: "",
        installTime: "",
        isResponsible: "",
      },
      deviceNumParams: {
        numNotNull: "true",
        status: 0,
        isContainsGroup: true,
        haveUser: "",
        categoryId: "",
        companyId: "",
      },
      isDetailEditMode: false,
      examine: {
        orderId: "",
        orderIds: [],
        rejectRemark: "",
        type: "",
      },
      imageUpdateParams: {
        templateDetailList: [],
      },
      imagePreviewUrl: "",
      imageSetting: {},
      deviceCategoryList: [],
      powerSourceCol: ["独立电表", "物业取电", "自家电表", "其他"],
      cableBrandCol: [],
      houseTypeList: [
        { id: 0, houseTypeName: "农村自建房" },
        { id: 1, houseTypeName: "城市小区" },
        { id: 2, houseTypeName: "城市自建房" },
        { id: 3, houseTypeName: "工厂" },
        { id: 4, houseTypeName: "其他" },
      ],
      choiceList1: [
        {
          value: 0,
          label: "未签",
        },
        {
          value: 1,
          label: "已签",
        },
      ],
      choiceListX: [
        {
          value: false,
          label: "否",
        },
        {
          value: true,
          label: "是",
        },
      ],
      choiceList2: [
        {
          value: 0,
          label: "未购买",
        },
        {
          value: 1,
          label: "已购买",
        },
      ],
      isEdit: [],
      sourceTypeListMap: {},
    };
  },
  beforeCreate() {
    listDictByType("问题来源").then((res) => {
      if (res.status === 200) {
        this.sourceTypeListMap = new Map(
          res.data.map((item) => [item.value, item.label])
        );
      }
    });
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.queryDeviceCategoryList();
    this.selMaterialType();
    this.queryCableBrandDrown();
    this.queryMaterialModelList();
    this.queryDetailList();
    this.queryFollow();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    houseTypeFormat,
    authorization,
    statusFormat,
    statusFormatByStatus,
    orderTypeFormat,
    repairTypeFormat,
    queryFollow() {
      this.axios({
        method: "get",
        //后端接口
        url: "/v1/web/order-info/get-order-all-follow/" + this.orderId,
      }).then((res) => {
        console.log(res.data.data);
        this.modelTableData = res.data.data;
      });
    },
    getCompany(companyId) {
      this.axios({
        method: "get",
        url: "/v1/web/company-manager/select-company",
        params: { companyIdEqual: companyId, page: 1, size: 10 },
      }).then((res) => {
        this.companyInfo = res.data.data.list[0];
        if (this.companyInfo != null && this.companyInfo.categoryIdList != null) {
          let categoryArr = this.companyInfo.categoryIdList;
          let deviceArr = this.deviceCategoryList.filter((x) => {
            return categoryArr.indexOf(x.id) >= 0;
          });
          this.deviceCategoryList = [...deviceArr];
        }
      });
    },
    queryCableBrandDrown() {
      this.axios({
        method: "get",
        url: "/v1/web/cable-brand/select-cable-brand",
      }).then((res) => {
        this.cableBrandCol = res.data.data.list;
        this.cableBrandCol.push({ id: -1, brandName: "其他" });
      });
    },
    queryDeviceCategoryList() {
      this.axios({
        method: "get",
        url: "/v1/web/device-category/select-category",
      }).then((res) => {
        this.deviceCategoryList = res.data.data.list;
      });
    },
    deleteMaterial(row) {
      this.axios({
        method: "delete",
        url: "/v1/web/order-info/delete-material/" + row.id,
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "删除成功", type: "success" });
          this.queryDetailList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //获取物料类型
    selMaterialType() {
      this.axios({
        method: "get",
        url: "/v1/h5/order-info/select-material-type-drown",
      }).then((res) => {
        this.materialList = res.data.data;
      });
    },
    queryMaterialModelList() {
      //获取物料类型列表
      let params = { size: -1 };
      this.axios({
        method: "get",
        url: "/v1/web/material-model/select-material-model",
        params: params,
      }).then((res) => {
        this.materialModelList = res.data.data.list;
      });
    },
    addMaterial() {
      this.addMaterialParams = {
        modeAndTypeId: [],
        orderId: "",
        typeId: "",
        modelId: "",
        num: 1,
        meterialPrice: "",
        actualAmount: 0,
      };
      this.dialogAddMaterial = true;
    },
    saveMaterial() {
      this.addMaterialParams.typeId = this.addMaterialParams.modeAndTypeId[0];
      this.addMaterialParams.modelId = this.addMaterialParams.modeAndTypeId[1];
      this.axios({
        method: "post",
        url: "/v1/web/order-info/add-material/" + this.tableData.id,
        data: this.addMaterialParams,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "添加成功", type: "success" });
          this.dialogAddMaterial = false;
          this.queryDetailList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    materialChanged(e) {
      if (
        e != null &&
        e.length > 1 &&
        this.materialModelList != null &&
        this.materialModelList.length > 0
      ) {
        let price = this.materialModelList.find((x) => x.id == e[1])?.price;
        this.addMaterialParams.meterialPrice = price;
        this.calcTotal(this.addMaterialParams);
      }
    },
    deviceUpdate() {
      this.deviceUpdateClick = true;
      this.getDeviceByCategoryX();
    },
    deviceUpdateCancle() {
      this.tableData = { ...this.row };
      this.deviceUpdateClick = false;
    },
    saveUpdate() {
      this.tableData.updateType = 0;
      this.axios({
        method: "post",
        url: "/v1/web/order-info/update-order-part/" + this.tableData.id,
        data: this.tableData,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "保存成功", type: "success" });
          this.deviceUpdateClick = false;
          this.queryDetailList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        if (index === 1 || index === 6) {
          sums[index] = "";
          return;
        }
        // if (index === 4) {
        //   sums[index] = sums[2] *  sums[3];
        //   return;
        // }
        const values = data.map((item) => Number(item[column.property]));
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      });
      return sums;
    },
    isDockingRequiredFormat(isDocking) {
      if (isDocking) {
        return "是";
      } else {
        return "否";
      }
    },
    getHouseTypeName(id) {
      const houseType = this.houseTypeList.find((item) => item.id === id);
      return houseType ? houseType.houseTypeName : "未知";
    },
    examineOrder(formName, type) {
      //驳回订单
      this.examine.type = type;
      this.axios({
        method: "post",
        url: "/v1/web/order-info/examine-order",
        data: this.examine,
      }).then((res) => {
        if (res.data.code === 200) {
          if (type == 4) {
            this.$message({ message: "驳回成功", type: "info" });
          } else if (type == 6) {
            this.$message({
              message: "审核通过",
              type: "success",
            });
          }
          this.tableData.status = type;
          this.dialogFormExamine = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    completeInstall() {},
    getWorkerId() {},
    getDeviceByCategoryX() {
      console.log(this.tableData);
      console.log(this.tableData.storeId);

      this.axios({
        method: "get",
        url: "/v1/web/device-manage/list-device-manage",
        params: this.deviceNumParams,
      }).then((res) => {
        this.deviceListX = res.data.data.list;
        console.log(this.deviceListX);
      });
    },

    scrollToDiv() {
      // 获取目标 div 并滚动到该元素
      const targetDiv = this.$refs.targetDiv;
      targetDiv.scrollIntoView({
        behavior: "smooth", // 平滑滚动效果
        block: "start", // 滚动到元素的顶部
      });
    },
    openCompleteDialog() {
      this.completeInstallForm.completeTimeX = this.dateFormat(
        this.tableData.installTime
      );
      this.completeInstallForm.detailAddressX = this.tableData.detailAddress;
      if (this.templateDetailList.length == 0) {
        this.scrollToDiv();
        this.showInfoNotification();
        return;
      }
      this.axios({
        method: "get",
        url: "/v1/web/order-info/installTime-detail",
        params: {
          orderId: this.orderId,
        },
      }).then((res) => {
        console.log(res.data.data);
        this.completeInstallForm.completeTimeX = res.data.data.completeTimeX;
        this.completeInstallForm.detailAddressX = res.data.data.detailAddressX;
      });

      this.dialogVisible = true;
      this.completeInstallForm.orderId = this.orderId;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showInfoNotification() {
      this.$notify({
        title: "提示信息",
        message: "请到图片信息中上传相关图片,再尝试完成安装",
        type: "error",
      });
    },
    submitForm() {
      console.log(this.completeInstallForm);

      this.$refs.form.validate((valid) => {
        if (valid) {
          // 如果表单验证通过，发送 POST 请求到后端
          this.axios({
            method: "post",
            url: "/v1/web/order-info/complete-order",
            data: this.completeInstallForm,
          })
            .then((response) => {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              response;
              this.dialogVisible = false;
              console.log(this.completeInstallForm);
              // 处理成功的响应
              this.queryDetailList();
            })
            .catch((error) => {
              error;
              // 处理请求错误
              this.$message({
                message: "提交失败，请重试",
                type: "error",
              });
            });
        } else {
          this.$message({
            message: "请检查表单输入",
            type: "warning",
          });
          return false;
        }
      });
    },
    examines() {
      this.dialogFormExamine = true;
      this.examine.orderId = this.row.id;
      this.examine.orderIds.push(this.examine.orderId);
    },
    totalPrice: function (row) {
      return row.num * row.meterialPrice;
    },
    getFollowRemarkList(followRemark) {
      if (followRemark === undefined || followRemark === null) {
        return [];
      }
      return followRemark.split(";");
    },
    goback() {
      this.$router.push({
        path:
          this.$route.query.isRepairOrder || this.$route.query.repairOrderTo
            ? "/home-admin/order/repair-order"
            : "/home-admin/order/order-manage",
        query: { activeName: this.activeName, page: this.page },
      });
    },
    downloadVideo() {
      this.imgDownloadLoading = true;
      this.axios({
        method: "post",
        url: "/v1/web/order-info/video-download",
        data: { orderId: this.row.id },
        responseType: "blob",
      })
        .then((res) => {
          let filename = res.headers["content-disposition"];
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute(
            "download",
            decodeURI(filename.substring(filename.indexOf("=") + 1, filename.length))
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.imgDownloadLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.imgDownloadLoading = false;
        });
    },
    cancelEditDetail() {
      this.isDetailEditMode = false;
    },
    saveEditdDetail() {
      console.log(this.editTime);
      console.log(this.tableData);
      this.axios({
        method: "post",
        url: "/v1/web/order-info/update-order-detail",
        data: this.editTime,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.isDetailEditMode = false;
          this.tableData.installTime = this.editTime.installTime;
          this.tableData.subscribeTime = this.editTime.subscribeTime;
          this.tableData.isDockingRequired = this.editTime.isDockingRequired;
          this.tableData.isResponsible = this.editTime.isResponsible;

          if (this.editTime.isResponsible === 1) {
            this.tableData.isResponsibleStr = "是";
          }
          if (this.editTime.isResponsible === 0) {
            this.tableData.isResponsibleStr = "否";
          }

          this.this.queryDetailList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    editDetail() {
      this.isDetailEditMode = true;
      this.editTime.orderId = this.orderId;
    },
    download() {
      //批量下载
      this.imgDownloadLoading = true;
      this.orderIdList = [];
      this.orderIdList.push(this.row.id);
      this.axios({
        method: "post",
        url: "/v1/web/order-info/image-download",
        data: { orderIds: this.orderIdList },
        responseType: "blob",
      })
        .then((res) => {
          let filename = res.headers["content-disposition"];
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/zip" }); //类型excel
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute(
            "download",
            decodeURI(
              filename.substring(filename.indexOf("=") + 1, filename.lastIndexOf("."))
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.imgDownloadLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.imgDownloadLoading = false;
        });
    },
    queryDetailList() {
      //获取订单详情数据
      this.axios({
        method: "get",
        url: "/v1/web/order-info/select-order-detail",
        params: { id: this.orderId },
      }).then((res) => {
        this.row = res.data.data;
        this.tableData = { ...this.row };

        if (this.tableData.sourceOrderId) {
          this.tableData.sourceOrderIdStr = this.sourceTypeListMap.get(
            this.tableData.sourceOrderId + ""
          );
        }
        if (this.tableData.isResponsible === true) {
          this.tableData.isResponsibleStr = "是";
          this.tableData.isResponsible = 1;
        }
        if (this.tableData.isResponsible === false) {
          this.tableData.isResponsibleStr = "否";
          this.tableData.isResponsible = 0;
        }

        console.log(this.tableData.masterWorker);
        this.deviceNumParams.haveUser = this.tableData.masterWorker;
        this.deviceNumParams.categoryId = this.tableData.categoryId;
        this.deviceNumParams.companyId = this.tableData.companyId;

        this.editTime.subscribeTime = this.dateFormat(this.tableData.subscribeTime);
        this.editTime.installTime = this.dateFormat(this.tableData.installTime);
        this.editTime.isDockingRequired = this.tableData.isDockingRequired;
        this.editTime.detailAddress = this.tableData.detailAddress;
        this.editTime.isResponsible = this.tableData.isResponsible;
        let surveyImageArr = [];
        if (
          res.data.data.surveyImageUrl !== null &&
          res.data.data.surveyImageUrl !== ""
        ) {
          surveyImageArr = res.data.data.surveyImageUrl.split(",");
        }
        let reserve1Array = [];
        if (res.data.data.reserve1 !== null) {
          reserve1Array = res.data.data.reserve1.split(",");
        }

        let reserve3Arr = [];
        if (res.data.data.reserve3 !== null) {
          reserve3Arr = res.data.data.reserve3.split(",");
        }

        this.reserve3List = [];
        for (let i = 0; i < reserve3Arr.length; i++) {
          this.reserve3List.push(this.changeImageUrl(reserve3Arr[i]));
        }

        this.surveyImageList = [];
        for (let i = 0; i < surveyImageArr.length; i++) {
          this.surveyImageList.push(this.changeImageUrl(surveyImageArr[i]));
        }

        this.otherImgList = [];
        for (let i = 0; i < reserve1Array.length; i++) {
          this.otherImgList.push(this.changeImageUrl(reserve1Array[i]));
        }

        this.courierImageList = [];
        let orderCourier = this.row.orderCourier;
        if (
          orderCourier != null &&
          orderCourier.courierImageUrl != null &&
          orderCourier.courierImageUrl != ""
        ) {
          this.courierImageList.push(this.changeImageUrl(orderCourier.courierImageUrl));
        }

        this.materialOrderList = res.data.data.materialOrderList;

        if (this.row.companyId != null && this.row.companyId != "") {
          this.getCompany(this.row.companyId);
        }

        this.selectImageSetting();
        this.selectOrderImageSettingDetailList();
      });
    },
    selectOrderImageSettingDetailList() {
      this.templateDetailList = [];
      this.axios({
        method: "get",
        url: "/v1/web/device-image-setting/select-order-image-detail",
        params: {
          orderId: this.orderId,
        },
      }).then((res) => {
        let resultData = res.data.data;
        if (resultData != null && resultData.length > 0) {
          this.templateDetailList = resultData;
        }
      });
    },
    changeImageUrl(imageUrl) {
      //转换图片路径
      if (imageUrl === null || imageUrl === "") {
        return "#";
      }
      return "/api/v1/web/order-info/preview-image?path=" + imageUrl;
    },
    resetImageUrl(imageUrl) {
      //转换为相对路径
      if (imageUrl === null || imageUrl === "") {
        return "";
      }
      return imageUrl.replace("/api/v1/web/order-info/preview-image?path=", "");
    },
    dateFormat(time) {
      if (time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    selectImageSetting() {
      let params = {
        orderType: this.tableData.orderType,
        repairType: this.tableData.orderType === 2 ? this.tableData.repairType : null,
        deviceCategoryId: this.tableData.storeId,
      };
      console.log(params.deviceCategoryId);
      this.axios({
        method: "get",
        url: "/v1/web/device-image-setting/select-image-setting",
        params: params,
      }).then((res) => {
        if (res.data.data.list.length > 0) {
          this.imageSetting = res.data.data.list[0];
        }
      });
    },
    saveImageUpdate() {
      if (
        this.imageUpdateParams.reserve1List != null &&
        this.imageUpdateParams.reserve1List.length > 0
      ) {
        let urlArr = [];
        for (let i = 0; i < this.imageUpdateParams.reserve1List.length; i++) {
          let image = this.imageUpdateParams.reserve1List[i];
          urlArr.push(this.resetImageUrl(image.url));
        }
        this.imageUpdateParams.reserve1 = urlArr.join(",");
      }

      if (
        this.imageUpdateParams.reserve3List != null &&
        this.imageUpdateParams.reserve3List.length > 0
      ) {
        let urlArr = [];
        for (let i = 0; i < this.imageUpdateParams.reserve3List.length; i++) {
          let image = this.imageUpdateParams.reserve3List[i];
          urlArr.push(this.resetImageUrl(image.url));
        }
        this.imageUpdateParams.reserve3 = urlArr.join(",");
      }

      if (
        this.imageUpdateParams.surveyImageList != null &&
        this.imageUpdateParams.surveyImageList.length > 0
      ) {
        let urlArr = [];
        for (let i = 0; i < this.imageUpdateParams.surveyImageList.length; i++) {
          let image = this.imageUpdateParams.surveyImageList[i];
          urlArr.push(this.resetImageUrl(image.url));
        }
        this.imageUpdateParams.surveyImageUrl = urlArr.join(",");
      }

      // if (this.imageUpdateParams.templateDetailList != null && this.imageUpdateParams.templateDetailList.length > 0) {
      //   for (let i = 0; i < this.imageUpdateParams.templateDetailList.length; i++) {
      //     let image = this.imageUpdateParams.templateDetailList[i];
      //     if (image.isRequired === 1 && (image.url == null || image.url == "" || image.miniUrl == null || image.miniUrl == "")) {
      //       this.$message.error(image.imageName + '不能为空');
      //       return;
      //     }
      //   }
      // }

      // if (this.imageUpdateParams.signExemption === 1 && (this.imageUpdateParams.reserve3 == null || this.imageUpdateParams.reserve3 == "")) {
      //   this.$message.error('订单已经签免责，免责图不能为空');
      //   return;
      // }

      // if (this.imageUpdateParams.surveyImageUrl == null || this.imageUpdateParams.surveyImageUrl == "") {
      //   this.$message.error('勘测确认图不能为空');
      //   return;
      // }

      this.imageUpdateParams.updateType = 1;
      this.axios({
        method: "post",
        url: "/v1/web/order-info/update-order-part/" + this.imageUpdateParams.id,
        data: this.imageUpdateParams,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "保存成功", type: "success" });
          this.dialogUpdateImage = false;
          this.queryDetailList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleImageReset() {
      this.$confirm("重置图片模板数据会清空当前订单的所有模板图片，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "delete",
          url: "/v1/web/order-info/resetImage/" + this.tableData.id,
          params: {
            id: this.tableData.id,
          },
        }).then((res) => {
          if (res.data.code === 200) {
            this.$message({ message: "重置图片模板成功", type: "success" });
            this.queryDetailList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      });
    },
    handleImageUpdate() {
      this.imageUpdateParams = JSON.parse(JSON.stringify(this.tableData));
      if (this.templateDetailList != null && this.templateDetailList.length > 0) {
        this.imageUpdateParams.templateDetailList = JSON.parse(
          JSON.stringify(this.templateDetailList)
        );
      } else {
        this.imageUpdateParams.templateDetailList = JSON.parse(
          JSON.stringify(this.imageSetting.templateDetailList)
        );
      }

      for (let i = 0; i < this.imageUpdateParams.templateDetailList.length; i++) {
        let img = this.imageUpdateParams.templateDetailList[i];
        if (
          img.miniUrl != null &&
          img.miniUrl != "" &&
          img.url != null &&
          img.url != ""
        ) {
          let imgObj = { name: img.imageName, url: this.changeImageUrl(img.miniUrl) };
          img.imgList = [];
          img.imgList.push(imgObj);
        }
      }

      this.imageUpdateParams.reserve3List = [];
      if (this.reserve3List != null && this.reserve3List.length > 0) {
        for (let i = 0; i < this.reserve3List.length; i++) {
          let imgObj = { name: "免责图" + i, url: this.reserve3List[i] };
          this.imageUpdateParams.reserve3List.push(imgObj);
        }
      }

      this.imageUpdateParams.surveyImageList = [];
      if (this.surveyImageList != null && this.surveyImageList.length > 0) {
        for (let i = 0; i < this.surveyImageList.length; i++) {
          let imgObj = { name: "勘测确认单" + i, url: this.surveyImageList[i] };
          this.imageUpdateParams.surveyImageList.push(imgObj);
        }
      }

      this.imageUpdateParams.reserve1List = [];
      if (this.otherImgList != null && this.otherImgList.length > 0) {
        for (let i = 0; i < this.otherImgList.length; i++) {
          let imgObj = { name: "其他图片" + i, url: this.otherImgList[i] };
          this.imageUpdateParams.reserve1List.push(imgObj);
        }
      }

      this.imageUpdateParams.videoList = [];
      if (
        this.imageUpdateParams.videoUrl != null &&
        this.imageUpdateParams.videoUrl != ""
      ) {
        let imgObj = {
          name: "视频",
          url: this.changeImageUrl(this.imageUpdateParams.videoUrl),
        };
        this.imageUpdateParams.videoList.push(imgObj);
      }

      this.dialogUpdateImage = true;
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.dialogImagePreview = true;
    },
    uploadVideo(file, fileList, type) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.uploadImage(file, type, false);
    },
    upload(file, fileList, type) {
      if (type === -1 || type === -2 || type === -3) {
        if (fileList.length >= 40) {
          this.$message.error("最多只能上传40张照片");
          return;
        }
      } else {
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }

        if (
          type > 0 &&
          this.imageUpdateParams.templateDetailList != null &&
          this.imageUpdateParams.templateDetailList.length > 0
        ) {
          let imgObj = this.imageUpdateParams.templateDetailList.find(
            (x) => x.id === type
          );
          imgObj.imgList?.splice(0, 1);
        } else if (type === -99) {
          this.imageUpdateParams.videoList?.splice(0, 1);
        }
      }

      this.clickUpload(type, file);
    },
    async clickUpload(type, file) {
      let miniFile = { ...file };
      let miniFileRaw, fileRaw;
      let miniSize = 100 * 1024;
      if (file.size > miniSize) {
        miniFileRaw = await this.compressFile(file.raw, 0.1);
        miniFile.raw = miniFileRaw;
      }

      let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
      if (file.size > maxSize) {
        fileRaw = await this.compressFile(file.raw, 0.2);
        file.raw = fileRaw;
      }

      if (type > 0) {
        this.uploadImage(miniFile, type, true);
      }

      this.uploadImage(file, type, false);
    },
    uploadImage(file, type, isMini) {
      let obj = {};
      obj.name = file.name;
      let param = new FormData(); //创建form对象
      param.append("files", file.raw);
      param.append("orderNum", this.imageUpdateParams.orderNum);
      this.axios({
        method: "post",
        url: "/v1/h5/order-info/image-upload",
        data: param,
      })
        .then((res) => {
          let imgUrl = res.data.data.imageUrl;
          obj.url = this.changeImageUrl(res.data.data.imageUrl);
          if (!isMini) {
            if (
              type > 0 &&
              this.imageUpdateParams.templateDetailList != null &&
              this.imageUpdateParams.templateDetailList.length > 0
            ) {
              let imgObj = this.imageUpdateParams.templateDetailList.find(
                (x) => x.id === type
              );
              imgObj.url = imgUrl;
              imgObj.imgList = [];
              imgObj.imgList.push(obj);
            } else if (type === -1) {
              //其他图片
              this.imageUpdateParams.reserve1List.push(obj);
            } else if (type === -2) {
              //免责图
              this.imageUpdateParams.reserve3List.push(obj);
            } else if (type === -3) {
              //勘测确认单
              this.imageUpdateParams.surveyImageList.push(obj);
            } else if (type === -99) {
              //视频
              this.imageUpdateParams.videoList = [];
              this.imageUpdateParams.videoList.push(obj);
              this.imageUpdateParams.videoUrl = imgUrl;
            }
          } else {
            this.setMiniImage(imgUrl, type);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("上传失败");
        });
    },
    commRemove(file, fileList, type) {
      if (
        type > 0 &&
        this.templateDetailList != null &&
        this.templateDetailList.length > 0
      ) {
        let imgObj = this.imageUpdateParams.templateDetailList.find((x) => x.id === type);
        imgObj.imgList = [];
        imgObj.url = null;
        imgObj.miniUrl = null;
      } else if (type === -1) {
        //其他图片
        let j = 0;
        for (let i = 0; i < this.imageUpdateParams.reserve1List.length; i++) {
          if (file.name === this.imageUpdateParams.reserve1List[i].name) {
            j = i;
          }
        }
        this.imageUpdateParams.reserve1List.splice(j, 1);
      } else if (type === -2) {
        //免责图
        let j = 0;
        for (let i = 0; i < this.imageUpdateParams.reserve3List.length; i++) {
          if (file.name === this.imageUpdateParams.reserve3List[i].name) {
            j = i;
          }
        }
        this.imageUpdateParams.reserve3List.splice(j, 1);
      } else if (type === -3) {
        //勘测图
        let j = 0;
        for (let i = 0; i < this.imageUpdateParams.surveyImageList.length; i++) {
          if (file.name === this.imageUpdateParams.surveyImageList[i].name) {
            j = i;
          }
        }
        this.imageUpdateParams.surveyImageList.splice(j, 1);
      } else if (type === -99) {
        //视频
        this.imageUpdateParams.videoList = [];
        this.imageUpdateParams.videoUrl = null;
      }
    },
    setMiniImage(miniImgUrl, type) {
      //压缩图片
      let miniImageObj = {};
      miniImageObj.url = this.changeImageUrl(miniImgUrl);
      if (type <= 0) {
        return;
      } else {
        //模板图片
        if (
          this.imageUpdateParams.templateDetailList != null &&
          this.imageUpdateParams.templateDetailList.length > 0
        ) {
          let image = this.imageUpdateParams.templateDetailList.find(
            (x) => x.id === type
          );
          image.miniUrl = miniImgUrl;
        }
      }
    },
    /**
     * 压缩图片
     * @param file
     * @returns {Promise<unknown>}
     */
    compressFile(file, quality) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: quality, //压缩质量
          checkOrientation: false, //图片翻转，默认为false
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    updateMaterial(index) {
      this.$set(this.isEdit, index, true);
    },
    updateMaterialSumbit(scoop) {
      this.axios({
        method: "post",
        url: "/v1/web/order-info/edit-material/" + this.tableData.id,
        data: scoop.row,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.queryDetailList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
      this.$set(this.isEdit, scoop.$index, false);
    },
    calcTotal(row) {
      row.allPrice = row.meterialPrice * row.num;
      row.actualAmount = row.allPrice;
    },
    addFollow() {
      this.followArr.orderId = this.orderId;
      this.dialogFollowAdd = true;
    },
    queryFollowList() {
      //获取跟进列表
      this.axios({
        method: "get",
        //后端接口
        url: "/v1/web/order-info/get-order-all-follow/" + this.orderId,
      }).then((res) => {
        this.modelTableData = res.data.data;
      });
    },
    addFollowSubMit() {
      this.axios({
        method: "post",
        url: "/v1/web/order-info/add-follow",
        data: this.followArr,
      }).then((res) => {
        if (res.data.code === 200) {
          this.dialogFollowAdd = false;
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.queryFollowList();
          this.getOrderCount();
          this.queryOrderList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
.el-table {
  overflow: visible !important;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-date-picker {
  width: 200px;
  height: 30px;
  font-size: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.install-time-text span {
  font-size: 12px;
}
</style>
